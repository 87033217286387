// USE .erb to have access to Rails.configuration
/* eslint-disable import/no-unresolved */
/* global $, LP */

import lazyAssetsObserver from 'js/lib/lazy_assets';
import posthog from 'posthog-js';

lazyAssetsObserver.observe();

const saveUpgradeStatusInLocalStorage = () => {
  // Save upgrade status in local storage
  try {
    setTimeout(function(){
      if ( $('.lp-header-nav .lp-upgrade a').length || $('.lp-header-nav .welcome-text').length ) {
        const upgradeStatus = 'no';
        localStorage.setItem('upgraded', upgradeStatus);
      } else {
        localStorage.removeItem('upgraded');
      }
    }, 3000);
  } catch (e) {}
};

const saveUsernameInLocalStorage = () => {
  // Save user name in local storage
  try {
    setTimeout(function(){
      if ($('#lp-username .username-wrap').length) {
        const userName = $('#lp-username .username-wrap').text();
        localStorage.setItem('name', userName);
      } else if ($('.lp-header-nav .welcome-text').length) {
        const userName = $('.welcome-text').text();
        const userNameClean = userName.replace('Logged in as: ', '');
        localStorage.setItem('name', userNameClean);
      } else {
        localStorage.removeItem('name');
      }
    }, 3000);
  } catch (e) {}
};

const saveLogoInLocalStorage = () => {
  // Save logo in local storage
  try {
    setTimeout(function(){
      if ($('.custom-sl-logo-wrap #logo').length) {
        const logoUrl = $('.custom-sl-logo-wrap #logo').attr('src');
        localStorage.setItem('logo', logoUrl);
      } else if ($('#le-logo').length) {
        const logoUrl = 'learningexplorer';
        localStorage.setItem('logo', logoUrl);
      } else {
        localStorage.removeItem('logo');
      }
    }, 3000);
  } catch (e) {}
};

const saveAdminManagerUrlInLocalStorage = () => {
  // Save admin manger url local storage
  try {
    setTimeout(function(){
      if ($('.lp-username-dd a[href^="/my/admin-manager/"]').length) {
        const adminManagerUrl = $('.lp-username-dd a[href^="/my/admin-manager/"]').attr('href');
        localStorage.setItem('adminmanagerurl', adminManagerUrl);
      } else {
        localStorage.removeItem('adminmanagerurl');
      }
    }, 3000);
  } catch (e) {}
};

export const loadFacebookScript = () => {
  return new Promise((resolve, reject) => {
    $.getScript('https://connect.facebook.net/en_US/sdk.js').done(function (
      url,
      result,
      key
    ) {
      if (result && typeof FB !== 'undefined') {
        // init the FB JS SDK
        FB.init({
          appId: '103869283846', // App ID from the app dashboard
          channelUrl: '//www.lessonplanet.com/channel.html', // Channel file for x-domain comms
          status: true, // Check Facebook Login status
          cookie: true, // enable cookies to allow the server to access the session
          xfbml: true, // Look for social plugins on the page
          version: 'v3.2'
        });
      }

      resolve(result);
    });
  });
};

export const loadPostHog = () => {
  const apiKey = "phc_82C7xQlwhAJtvPVg5bazuANLB19m4CBJ49Q7i7NScqr";
  posthog.init(apiKey, {
    api_host: 'https://ph.lessonplanet.com',
    ui_host: 'https://app.posthog.com'
  });
};

export const loadPinterestScript = () => {
  return new Promise((resolve, reject) => {
    $.getScript('https://assets.pinterest.com/js/pinit.js').done(function (
      url,
      result,
      key
    ) {
      resolve(result);
    });
  });
};

$(function ready() {
  LP.initFromElement(document.body);
  saveUpgradeStatusInLocalStorage();
  saveUsernameInLocalStorage();
  saveLogoInLocalStorage();
  saveAdminManagerUrlInLocalStorage();
});
